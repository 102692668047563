/*
 * @Author: fanzh fanzh@leadingcloud.xyz
 * @Date: 2022-09-25 15:25:33
 * @LastEditors: fanzh fanzh@leadingcloud.xyz
 * @LastEditTime: 2022-10-13 17:34:49
 * @FilePath: /ldc-fe-site-xiaoke/src/components/layout.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as React from "react"
// import { useState } from 'react'
// import { useEffect } from 'react';
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

// import Navbar from "./NavBar"
import "./layout.scss"
import FooterFc from "./footerFc"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)


//   useEffect(() => {
//     currentSystem()
//   }, [])
  
//   const currentSystem = () => {
//     if (typeof window !== `undefined`) {
//         const u = navigator.userAgent;
//         const browser = {//移动终端浏览器版本信息 
//             trident: u.indexOf('Trident') > -1, //IE内核
//             presto: u.indexOf('Presto') > -1, //opera内核
//             webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
//             gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
//             mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
//             ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
//             android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
//             iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
//             iPad: u.indexOf('iPad') > -1, //是否iPad  
//             webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
//             weixin: u.indexOf('MicroMessenger') > -1, //是否微信 
//             qq: u.match(/\sQQ/i) == "qq",//是否QQ
//         };
//         if (browser.ios || browser.iPhone || browser.iPad||browser.android) {
//             const a = document.createElement('a');
//             a.href = '/m';
//             a.click();
//         }
//     }else{
//         console.log(`window：${window}`)
//     }

// }

  return (
    <>
        <div className="container-fluid p-0">
          {/* <Navbar siteTitle={data.site.siteMetadata?.title || `晓客管家`} /> */}
          <main>
            {children}
          </main>
          <FooterFc />
        </div>
    </>
  )


}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
